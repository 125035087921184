import React from 'react';
import { useLocation } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import Error404 from '../components/ErrorPages/Error404';
import Error502 from '../components/ErrorPages/Error502';
import DefaultError from '../components/ErrorPages/DefaultError';
import { ErrorMessage } from '../models/models';

function getErrorPage(state: ErrorMessage) {
  switch (state.statusCode) {
    case StatusCodes.NOT_FOUND:
      return <Error404 statusCode={state.statusCode} error={state.error} />;
    case StatusCodes.BAD_GATEWAY:
      return <Error502 statusCode={state.statusCode} error={state.error} />;
    default:
      return <DefaultError statusCode={state.statusCode} error={state.error} />;
  }
}

function ErrorPage() {
  const state = useLocation().state as ErrorMessage;
  return <>{getErrorPage(state)}</>;
}

export default ErrorPage;
