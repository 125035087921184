import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CredentialViewer from './containers/CredentialViewer/CredentialViewer';
import ErrorPage from './containers/ErrorPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<CredentialViewer />} />
        <Route path="/error" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
