import React from 'react';
import { ErrorMessage } from '../../models/models';

const DefaultError: React.FC<ErrorMessage> = function ({
  statusCode,
  error,
}: ErrorMessage) {
  return (
    <>
      {statusCode !== undefined ? <div>Error Code: {statusCode}</div> : ''}
      {error !== undefined ? <div>Error: {error}</div> : ''}
    </>
  );
};

export default DefaultError;
