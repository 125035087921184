import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { VerifiedCredential, VerificationResponse } from '../../models/models';

const CredentialViewer = function () {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const vid = params.get('vid');
  const sid = params.get('sid');
  const url = params.get('url');

  const [verifiedCredentials, setVerifiedCredentials] = useState<
    VerifiedCredential[] | undefined
  >([]);

  const navigate = useNavigate();

  const throwErrorPage = (statusCode: number, error: string) => {
    navigate('/error', {
      state: {
        statusCode,
        error,
      },
    });
  };

  useEffect(() => {
    if (vid === null || sid === null || url === null) {
      throwErrorPage(
        StatusCodes.BAD_REQUEST,
        'Bad Request - The required parameters are missing.'
      );
    } else {
      const reqBody = {
        consortiumURL: url,
        serviceID: sid,
        verificationID: vid,
      };
      axios
        .post<VerificationResponse>(`api/verifications`, reqBody)
        .then(response => {
          if (response?.data?.verifiedCredentials) {
            setVerifiedCredentials(response?.data?.verifiedCredentials);
          } else {
            throwErrorPage(
              StatusCodes.INTERNAL_SERVER_ERROR,
              'Internal Server Error'
            );
          }
        })
        .catch(err => {
          const httpStatus = err.response.status;
          const errMessage = err?.response?.data?.error || err.message;
          throwErrorPage(httpStatus, errMessage);
        });
    }
  }, [vid, sid, url]);

  return (
    <>
      CredentialViewer Page:
      {verifiedCredentials?.map(credential => (
        <div key={credential.name}>
          {credential.name}: {credential.value?.value}
        </div>
      ))}
    </>
  );
};

export default CredentialViewer;
